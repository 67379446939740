import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { compact, get, last } from "lodash";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import { ContentBlock, TextBlock, VirtualTourBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Pages from "../../components/Pages";
import { makeUrl, removePreloader } from "../../helpers";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import EmptyData from "../../components/EmptyData";
import { VIRTUAL_TOUR_MAX_UPDATE, VIRTUAL_TOUR } from "../../queries/queries.graphql";

export const query = graphql`
	query virtualTourDataQuery($slug: String!) {
		hasura {
			...VirtualTour
		}
	}
`;

export default function VirtualTourPage({ data, location }) {
	const path = last(compact(location.pathname.split("/")));

	const [tourData, setTourData] = useState(get(data, "hasura.virtual_tours[0]", []));

	const maxUpdated = new Date(get(data, "hasura.virtual_tours_aggregate.aggregate.max.updated_at", new Date()));

	const tourDataOptions = {
		fetchPolicy: "no-cache",
		variables: {
			slug: path,
		},
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(VIRTUAL_TOUR_MAX_UPDATE, tourDataOptions);
	const [loadTourData, { loading: dataLoading, called, data: fetchData, error: dataError }] = useLazyQuery(VIRTUAL_TOUR, tourDataOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "virtual_tours_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadTourData();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load tourDataMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (called && fetchData && !dataError) {
			setTourData(get(fetchData, "virtual_tours[0]", {}));
		}

		removePreloader(!dataLoading || dataError);
	}, [dataLoading]);

	const url = makeUrl.virtualTour(tourData);
	const imageSrc = get(tourData, "main_image.src", "");

	const { main_image_preview, page_title_full, content_blocks, settings } = tourData;

	useEffect(() => {
		removePreloader();
	}, []);

	const breadcrumbsData = {
		item: {
			title_full: "медиатека",
			slug: "media",
		},
		item2: {
			title_full: "виртуальные экскурсии",
			slug: "",
		},
	};

	return tourData ? (
		<Pages entity={tourData} url={url} >
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={page_title_full} pageContext={breadcrumbsData} />
				</div>
			</ContentBlock>
			<ContentBlock key={"media-content"} className={"text-page"}>
				<div className={"container"}>
					<TextBlock title={page_title_full}>
						<VirtualTourBlock
							component={get(settings, "component", "")}
							settings={settings}
							preview={get(main_image_preview, "src", "")}
						/>
						<Contents items={content_blocks} />
					</TextBlock>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleFull={page_title_full} imageSrc={imageSrc} />
			</div>
		</Pages>
	) : (
		<EmptyData />
	);
}

VirtualTourPage.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
	location: PropTypes.object,
};

VirtualTourPage.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
	pageContext: {},
};
